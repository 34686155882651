import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import leftArrow from "../component/images/left-arrow.png";
import rightArrow from "../component/images/right-arrow.png";
import "../styles/carousel.css";

export default function Section3({ deviceWidth }) {
  const carouselRef = useRef(null);

  console.log(deviceWidth);

  const goToPrevItem = () => {
    carouselRef.current.prev();
  };

  const goToNextItem = () => {
    carouselRef.current.next();
  };

  return (
    <div className="w-full">
      <div className="flex flex-row pt-8 lg:pt-32 relative">
        <OwlCarousel
          ref={carouselRef}
          loop
          dots={false}
          items={5}
          center
          responsive={{
            0: { items: 1.8 },
            600: { items: 3 },
            1000: { items: 5 },
            1600: { items: 8 },
            2000: { items: 12 },
          }}
          className="carousel-video"
        >
          <div className="item bg-slate-400"></div>
          <div className="item bg-slate-500"></div>
          <div className="item bg-slate-400"></div>
          <div className="item bg-slate-500"></div>
          <div className="item bg-slate-400"></div>
          <div className="item bg-slate-500"></div>
        </OwlCarousel>
      </div>
      <div className="flex justify-center mt-4">
        <button className="p-3" onClick={goToPrevItem}>
          <img src={leftArrow} alt="Previous" className="h-12 mr-3" />
        </button>
        <button className="p-3" onClick={goToNextItem}>
          <img src={rightArrow} alt="Next" className="h-12" />
        </button>
      </div>
    </div>
  );
}
