import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isMobile } from "react-device-detect";

export default function Banner({ inclination }) {
  return (
    <div className="bg-main-green py-5 " style={{ transform: `rotate(${inclination}deg)`, width: "102%" }}>
      {!isMobile ? (
        <OwlCarousel
          dots={false}
          autoWidth
          autoplay
          loop
          centered
          mouseDrag={false}
          pullDrag={false}
          freeDrag={false}
          touchDrag={false}
          autoplaySpeed={5000}
        >
          <div className="flex flex-row text-5xl">
            <div className="flex flex-row">
              <p>TALK</p>
              <div className="border-white border-[2px] h-[40px] mx-5" />
            </div>
            <div className="flex flex-row">
              <p>PROIEZIONE VIDEO</p>
              <div className="border-white border-[2px] h-[40px] mx-5" />
            </div>
            <div className="flex flex-row">
              <p>COCKTAIL E CENA</p>
              <div className="border-white border-[2px] h-[40px] mx-5" />
            </div>
          </div>
          <div className="flex flex-row font-bold">
            <div className="text-5xl flex flex-row">
              <p>DJ SET</p>
              <div className="border-white border-[2px] h-[40px] mx-5" />
            </div>
            <div className="text-5xl flex flex-row">
              <p>MUSICA DAL VIVO</p>
              <div className="border-white border-[2px] h-[40px] mx-5" />
            </div>
          </div>
        </OwlCarousel>
      ) : (
        <OwlCarousel
          dots={false}
          autoWidth
          autoplay
          loop
          centered
          mouseDrag={false}
          pullDrag={false}
          freeDrag={false}
          touchDrag={false}
          autoplaySpeed={5000}
        >
          <div className="flex flex-row">
            <p className="text-4xl">TALK</p>
            <div className="border-white border-[2px] h-[40px] mx-5" />
          </div>
          <div className="flex flex-row">
            <p className="text-4xl">PROIEZIONE VIDEO</p>
            <div className="border-white border-[2px] h-[40px] mx-5" />
          </div>
          <div className="flex flex-row">
            <p className="text-4xl">COCKTAIL E CENA</p>
            <div className="border-white border-[2px] h-[40px] mx-5" />
          </div>
          <div className="flex flex-row">
            <p className="text-4xl">DJ SET</p>
            <div className="border-white border-[2px] h-[40px] mx-5" />
          </div>
          <div className="flex flex-row">
            <p className="text-4xl">MUSICA DAL VIVO</p>
            <div className="border-white border-[2px] h-[40px] mx-5" />
          </div>
        </OwlCarousel>
      )}
    </div>
  );
}
