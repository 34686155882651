import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import leftArrow from "../component/images/left-arrow.png";
import rightArrow from "../component/images/right-arrow.png";
import { isMobile } from "react-device-detect";

export default function Section6() {
  const carouselRef = useRef(null);

  const goToPrevItem = () => {
    carouselRef.current.prev();
  };

  const goToNextItem = () => {
    carouselRef.current.next();
  };
  return (
    <div className="w-full">
      <div className="conatainer flex flex-col justify-center items-center pb-20 lg:pb-32">
        <p className="text-6xl mb-8 ">PROGRAMMA</p>

        <div className="w-full">
          {!isMobile ? (
            <OwlCarousel ref={carouselRef} loop dots={false} items={1} className="px-24">
              <div className="w-full flex flex-row">
                <div className="w-1/2 flex flex-col items-center justify-center">
                  <div className="text-left">
                    <p className="text-main-green text-5xl ">ORE 18:00</p>
                    <p className=" text-4xl ">Proiezione video</p>
                    <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
                  </div>
                </div>

                <div className="bg-main-green w-1 h-64" />

                <div className="w-1/2 flex flex-col items-center justify-center">
                  <div className="text-left">
                    <p className="text-main-green text-5xl  ">ORE 20:00</p>
                    <p className=" text-4xl ">Proiezione video</p>
                    <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-row">
                <div className="w-1/2 flex flex-col items-center justify-center">
                  <div className="text-left">
                    <p className="text-main-green text-5xl ">ORE 22:00</p>
                    <p className=" text-4xl ">Proiezione video</p>
                    <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
                  </div>
                </div>

                <div className="bg-main-green w-1 h-64" />

                <div className="w-1/2 flex flex-col items-center justify-center">
                  <div className="text-left">
                    <p className="text-main-green text-5xl  ">ORE 00:00</p>
                    <p className=" text-4xl ">Proiezione video</p>
                    <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          ) : (
            <OwlCarousel ref={carouselRef} loop dots={false} items={1.2} center className="">
              <div className="flex flex-col text-center mx-1">
                <p className="text-main-green text-5xl ">ORE 18:00</p>
                <p className=" text-4xl ">Proiezione video</p>
                <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
              </div>
              <div className="flex flex-col text-center mx-1">
                <p className="text-main-green text-5xl ">ORE 20:00</p>
                <p className=" text-4xl ">Proiezione video</p>
                <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
              </div>
              <div className="flex flex-col text-center mx-1">
                <p className="text-main-green text-5xl ">ORE 22:00</p>
                <p className=" text-4xl ">Proiezione video</p>
                <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
              </div>
              <div className="flex flex-col text-center mx-1">
                <p className="text-main-green text-5xl ">ORE 00:00</p>
                <p className=" text-4xl ">Proiezione video</p>
                <p className="text-2xl font-eaves-reg">Lorem ipsum Lorem ipsum Lorem ipsum</p>
              </div>
            </OwlCarousel>
          )}
        </div>
        <div className="flex justify-center mt-4 lg:mt-16">
          <button className="p-3" onClick={goToPrevItem}>
            <img src={leftArrow} alt="" className="h-12 mr-6"></img>
          </button>
          <button className="p-3" onClick={goToNextItem}>
            <img src={rightArrow} alt="" className="h-12"></img>
          </button>
        </div>
      </div>
    </div>
  );
}
