import React from "react";

export default function Footer({ email, setEmail, onSubmit }) {
  return (
    <div className="w-full bg-main-green">
      <div className="container mx-auto py-4 flex flex-col lg:flex-row justify-center items-center">
        <p className="text-4xl font-bold mx-10">ISCRIVITI</p>
        <input
          type="email"
          placeholder=""
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          className="bg-white text-black w-64 lg:w-96"
        />
        <input type="submit" value="SUBMIT" onClick={onSubmit} className="mx-10 bg-main-blue px-10 font-eaves-reg mt-4 lg:mt-0" />
      </div>
    </div>
  );
}
