import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Section1 from "./section/Section1";
import Section2 from "./section/Section2";
import Section3 from "./section/Section3";
import Section4 from "./section/Section4";
import Section5 from "./section/Section5";
import Section6 from "./section/Section6";
import Footer from "./section/Footer";

function App() {
  const [email, setEmail] = useState("");

  function onSetEmail(value) {
    setEmail(value);
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function onSubmit() {
    if (validateEmail(email)) {
      toast.success("Email submitted successfully!");
      setEmail("");
    } else {
      toast.error("Invalid email address. Please enter a valid email.");
    }
  }

  return (
    <div className="bg-main-blue w-full flex flex-col m-0 p-0 text-white font font-eaves-heavy">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Footer email={email} setEmail={onSetEmail} onSubmit={onSubmit} />
      <ToastContainer />
    </div>
  );
}

export default App;
