import React from "react";

export default function Section2() {
  return (
    <div className="w-full  pb-20">
      <div className="container flex flex-col lg:flex-row">
        <div className="flex lg:w-1/2">
          <div className="bg-gray-500 h-[250px] w-[350px] mx-auto lg:h-[500px] lg:w-[500px]" />
        </div>
        <div className="text-center mt-4 lg:mt-0 lg:text-right lg:w-1/2 ">
          <div className="w-full mb-6 text-4xl lg:text-6xl ">
            <h1>L'evento conclusivo </h1>
            <h1>del nostro </h1>
            <h1>Tour mondiale</h1>
          </div>
          <div className="px-3 lg:px-0 lg:pl-40 text-3xl font-eaves-reg">
            <p>
              Sarà una festa incredibile in casa latteria Sorrentina con la presenza di tutti i nostri amici, clienti e fornitori!
              Tutti insieme vivremo una serata speciale con buon cibo, musica, artisti e altre sorprese.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
