import { React } from "react";
import Banner from "../component/banner";

export default function Section4() {
  return (
    <div className="flex flex-col justify-center items-center py-20 lg:py-40 overflow-hidden">
      <Banner inclination={-5}></Banner>
      <p className="text-main-green text-7xl mt-12 lg:text-9xl  lg:mt-24">11.09.2024</p>
      <p className="text-3xl lg:text-4xl">Sant'Anastasia (NA)</p>
      <p className="text-2xl lg:text-3xl font-eaves-reg">via Somma 80</p>
    </div>
  );
}
