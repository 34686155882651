import { React } from "react";
import Logo from "../component/images/Logo.png";
import Banner from "../component/banner";

export default function Section1() {
  return (
    <div className="flex flex-col justify-center items-center py-28 lg:py-40 overflow-hidden">
      <img src={Logo} alt="" className="w-40 mb-5" />
      <div className="flex flex-row justify-between text-main-green mb-4 text-2xl lg:text-4xl w-44 lg:w-96">
        <p>W</p>
        <p>O</p>
        <p>R</p>
        <p>L</p>
        <p>D</p>
      </div>
      <p className="text-4xl lg:text-6xl">FIORDILATTE</p>
      <p className="mb-16 text-7xl lg:text-9xl">SUMMIT</p>
      <Banner inclination={5} />
    </div>
  );
}
